import React from "react";
import "./HomePage.scss";
import { useMediaQuery } from "react-responsive";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


const HomeBlock1 = () => {
  return <div className="home__block1">
    <h2>COMUNICADO OFICIAL:</h2>
    <p>Yo <b>JERÓNIMO SÁNCHEZ CUENCA</b>, doy paso al siguiente comunicado sobre la Federación Andaluza de Béisbol y Sófbol (FABS); lamentablemente no me es grato escribir esta misiva, es un día muy triste para mí.

      La FABS, se está herida de muerte. Nos embarga una profunda impotencia, sentimiento compartido de forma conjunta por esta presidencia, la secretaria, y la actual Junta Directiva.
      En el momento que asumimos los cargos ostentados desde el 20-01-2023, tras la inhabilitación de la anterior presidencia y un tortuoso proceso electoral,sabíamos que el camino no iba a ser fácil. Todos los que amamos estas disciplinas éramos conscientes del arduo camino que teníamos ante nuestros ojos. No tardaron mucho en aparecer correos electrónicos comprometedores de gestiones anteriores, presidentes y juntas de gobierno de un amplio tiempo pasado (2016/2022).
      <br />
      <br />
      Contábamos con poco tiempo para poner en marcha la presente temporada. Las “injusticias de gestión” cometidas, vamos a denominarlo así, y la “herencia envenenada” de estos años, por quienes han llevado el timón de una Federación deportiva que, representando a un deporte minoritario, que ya de por sí, no lo tenía fácil frente a otras disciplinas para hacerse visible en nuestro entorno próximo, en muestra de una clara actitud y acción, que solo puede definirse como “PRESUNTAMENTE CORRUPTA”.
      <br />
      <br />
      Pero contaba con la ilusión de personas que querían mostrar, no sin un trabajo titánico, pues los frentes abiertos eran y son muchos. Tuvimos la idea para unir en una sola voz, aunar voluntades que venían desde diferentes perspectivas de trabajo, se podía funcionar dentro de la legalidad vigente y el orden federativo. Y de esta forma, sacar a flote lo que no queríamos ver hundido, pues teníamos fe en el orden institucional y organizativo, para el buen funcionamiento y desarrollo deportivo. Hemos demostrado, con el esfuerzo de casi todos, que las cosas se podían hacer de otra forma y así lo se ha hecho desde el 20 de enero que entramos en la directiva de esta Federación. Teníamos que hacer valer la ilusión y el trabajo de los jóvenes, de los cuerpos técnicos, en definitiva, de aquellos clubes que también trabajan día a día muy duro en sus provincias para salir adelante. Recobramos por momentos la ILUSIÓN.
      <br />
      <br />
      Voy a hablar de hechos concretos, debido a la gestión de los dos mandatos presidenciales anteriores, en la que, tras un presidente inhabilitado, y la autoproclamación de su vicepresidenta como la cabeza visible de la FABS, observaremos, a través de tanto documentos como extracto bancario, que comienza a disiparse la presunción de corrupción, para ir tomando forma la constatación de posibles delitos consumados. La FABS, había adquirido una deuda de más de 60.000 €, fruto de las Subvenciones recibidas de la Junta de Andalucía, para los años 2021 y 2022, y que hay que devolver.

      EL DINERO FUE GASTADO, DE FORMA EVIDENTEMENTE CUESTIONABLE Y NO PUDIENDO JUSTIFICAR SU GASTO HAY QUE DEVOLVERLA INTEGRAMENTE.
    </p>
    <p>
      <b>Federación Andaluza de Béisbol y Sófbol CIF G-41536970. C/Huelva S/N. Escuela Taller. 14910 Benamejí. Córdoba.</b>  Quiero anotar, para conocimiento de todas y todos, que desde el 2016, se ha adquirido material deportivo por parte de la FABS por un valor superior a 70.000€. La respuesta de la anterior gestión es que, “ese material no existe”. Estamos ante una presunta falsedad documental, de su facturación, cuestión gravísima de un organismo deportivo que tiene competencias delegadas directamente de la Junta de Andalucía. A la par, hallamos, conversaciones vía e-mail con el presidente de la COFEDERACION ANDALUZA DE FEDERACIONES DEPORTIVAS (CAFD), muy interesantes, días después de mi nombramiento como Presidente de la FABS, documento adjuntado a este comunicado.
      <br /><br />
      Todo lo anteriormente mencionado, fue puesto en conocimiento de la Secretaria General para el Deporte. Con estas presuntas evidencias delictivas (que podían ser falsedad en la facturación, falsificación de firmas, malversación de fondos públicos y un largo etc…) y tras cuatro reuniones con el organismo competente, creímos en la mano tendida desde la administración, para poder salvar y curar la “herida abierta” que nos encontramos y claro está depurar las responsabilidades pertinentes de unas personas por su mal proceder y de otras, invigilantes de aquellas competencias que siendo delegadas se estaban utilizando de forma presuntamente fraudulenta y siempre tuvieron indicios, pero al parecer nunca fueron suficientes. En estos 8 meses de mandato, nos hemos “deslomado”, por justificar la subvención del 2022, con la carencia de documentación recibida. Nos insisten en la devolución en su totalidad. Justo es que, habiendo recibido dinero de todos los andaluces, sea reembolsado, si no se ha procedido como manda la normativa vigente. Pero, por parte de la administración, la solución planteada para su ejecución, es el abono entre todos los Clubes afiliados. De los cuales una mayoría considerable, llevan tantos años denunciando estas irregularidades. Y, aun así, teniendo conocimiento y observando que algo raro pasa; corroborado por técnicos de la Junta de Andalucía y sus responsables, como por ejemplo la cantidad de partidos en la temporada pasada 2022 ante las pocas licencias tramitadas, a lo que se alegó por nuestra parte, su falsedad, fácilmente demostrable. Y, AUN ASÍ, SE SIGUEN CONCEDIENDO EN LA ACTUALIDAD SUBVENCIONES A LA FABS.
      <br /><br />
      Esto hace que pongamos en tela de juicio, la actuación de ciertos Técnicos de los servicios de la Junta de Andalucía, mostrando una prisa inusitada en que esta gestión de 2023 seamos los máximos responsables. Desde la Secretaría de esta Federación, se anotó la permisividad, con los indicios manifiestos, por estos servicios de la administración autonómica en materia deportiva, actitud, demostrable vía documental. La respuesta obtenida, fue que en cinco años todo es objeto de olvido… SORPRENDENTE. Indicar que estos hechos tuvieron lugar al finalizar la primeria reunión con la Directora General de Sistemas y Valores del Deporte (sin estar ella presente), en Torre Triana (Sevilla).
      <br /><br />
      <b>Federación Andaluza de Béisbol y Sófbol CIF G-41536970. C/Huelva S/N. Escuela Taller. 14910 Benamejí. Córdoba.</b>  Como hemos mencionado con anterioridad, actualmente se le ha concedido a la FABS una Subvención de 12.235€, a la cual renunciaremos, tras exponerlo tanto en la Junta Directiva, como en la próxima Asamblea General Extraordinaria que se va a convocar en esta Federación, para consensuar las decisiones a tomar. Para nada es nuestro deseo aumentar la DEUDA de la FABS. Asimismo, se decidirá la extinción de actividad de la FABS y por consiguiente de la propia Federación en sí.
      <br /><br />
      No quiero finalizar este comunicado sin AGRADECER sobre todo a aquellos CLUBES que creen en nosotros. Siendo honestos también agradecemos el que se nos haya recibido y atendido en los Servicios deportivos de la Junta de Andalucía. No podemos decir lo mismo de su ayuda.
      <br /><br />
      Y a aquellos y aquellas que tanto daño han hecho, con su supuesto desconocimiento con lo legalmente establecido, a lo que querían mostrar como una defensa férrea del deporte, no se preocupen la justicia llegará, al igual que para aquellos servidores de lo público, que han mirado para otro lado.
      <br /><br />
      Por parte de la actual Presidencia y Junta Directiva de la FABS, decirles a todos nuestros afiliados y afiliadas, que seguiremos en la defensa de dos grandes disciplinas deportivas que aportan algo más que un buen estado físico y son esos VALORES QUE SIRVEN PARA TODA UNA VIDA, y continuaremos jugando al Béisbol y el Sófbol.
      <br /><br />
      ¡¡¡ GRACIAS!!!
      <br /><br />
      Benamejí a 28 de agosto de 2023</p>


  </div>;
};

const HomeBlock2 = () => {
  return (
    <div className="home__block2">
      <h2>NOTA DE PRENSA</h2>
      <p>Desde que aceptamos el llevar a esta Federación por el mejor camino posible, tras la herencia recibida, fruto de una gestión nefasta enquistada en varias directivas consecutivas, no nos quedaba más que mostrar que las cosas se podían hacer de otra manera, sobre todo con mucha transparencia y rigor para quienes se acercaban a formar parte tanto en la directiva como los clubes afiliados. Y hemos de decir que la mayoría de los Clubes así lo han hecho.
<br /><br />

        Pero… y siempre hay un, pero. No podemos decir lo mismo de los servicios
        deportivos de la Junta de Andalucía y más concretamente de la Dirección General de Sistemas y Valores del Deporte, con Dña. María de Nova Pozuelo a la cabeza y sus técnicos. Si, hemos sido recibidos en varias ocasiones y, nunca esta Federación se negó a cumplir con sus obligaciones y con las consecuencias acarreadas de los mandatos anteriores. Pretendíamos hacerlo de manera que no se invalidara la función principal para lo que está concebida una Federación Deportiva, regular y organizar nuestras disciplinas, el Béisbol y el Sófbol, y hacer.
<br /><br />
        valer el deporte federado concienciando a todos los componentes de esta Federación. Ahí era donde esperábamos ver la ayuda. Todo fue una quimera…. Mas, hasta aquí hemos llegado. Observen bien los membretes, los fondos, los sellos. Comienza la cuenta atrás para la disolución de esta federación, por imposibilidad de poder continuar, gracias a las negligentes directivas anteriores y a una invigilancia manifiesta de la administración pública, que nos ha llevado a un equerimiento de embargo. Las responsabilidades, llegarán.
<br /><br />
        Y nosotros por nuestra parte continuaremos trabajando por hacer valer nuestras disciplinas y a los Clubes que tanto trabajo llevan a sus espaldas y que han obrado de forma correcta y diligente y a los que tanto agradecemos.
<br /><br />
        SIEMPRE UNIDOS, SIEMPRE FUERTES
        <br /><br />
        En Benamejí a 11 de octubre de 2023.

      </p>
    </div>
  )
}

const HomePage = () => {
  return (
    <div className="home">
      <HomeBlock1 />
      <HomeBlock2 />
    </div>
  );
};

export default HomePage;
